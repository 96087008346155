<template>
    <div>
        <div class="variantqty" v-if="option.label && option.label != 'Defaut'">
            <div class="variantqty-spot">
                <VariantSpot :option="option" />
            </div>
            <div class="variantqty-label">
                {{ option.label }}
                <span class="variantqty-label-info">indiquer les quantités :</span>
            </div>
        </div>
        <div class="variantqty" style="margin-top: 8px;">
            <template v-if="option.subVariants">
                <div class="variantqty-field" v-for="subvariant in option.subVariants" @click="selectSubvariant(subvariant)" :class="{'is-disabled': !variantEnabled(optionSlug, subvariant.id).enabled}">
                    <div>{{ subvariant.label }}</div>
                    <input type="number" class="variantqty-input" min="0"
                           :value="subvariant.config.quantity > 0 ? subvariant.config.quantity : ''"
                           @input="setQuantity($event, optionSlug, subvariant.id)">
                </div>
            </template>
            <template v-else>
                <div class="variantqty-field">
                    <div>{{ $t('quantity') }}</div>
                    <input type="number" class="variantqty-input" min="0"
                           :value="option.config.quantity > 0 ? option.config.quantity : ''"
                           @input="setQuantity($event, optionSlug)">
                </div>
            </template>
            <div class="variantqty-error" v-if="qtyErrors && qtyErrors.total">
                Quantité minimum : <b>{{ qtyMin }}</b>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import VariantSpot from "../variant/VariantSpot.vue"

    export default {
        components: { VariantSpot },
        props: {
            variantSlug: String,
            option: Object,
            optionSlug: [Number, String]
        },
        computed: {
            ...mapGetters({
                variantEnabled: 'marquage/variantEnabled',
                qtyErrors: 'cart/quantitiesErrors',
                qtyMin: 'cart/qtyMin'
            }),
        },
        methods: {
            setQuantity(event, optionSlug, subVariantSlug = null) {
                let qty = 0
                if (event.target.value.length > 0) {
                    qty = event.target.value
                }
                this.$store.commit('variants/setOptionQuantity', {
                    variantId: this.variantSlug,
                    optionId: optionSlug,
                    subVariantId: subVariantSlug,
                    quantity: qty
                })
            },
            selectSubvariant(subvariant) {
                this.$store.commit('variants/selectSubvariant', {
                    subvariantId: subvariant.id
                })
            }
        }
    }
</script>
