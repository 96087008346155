<template>
    <div>
        <div v-if="lightMode">
            <swiper class="sidebar-images" style="margin-top: 15px;" :options="swiperOptions">
                <swiper-slide v-for="(image, imageIndex) in lightLogos" :key="image.imageObj.id">
                    <img :src="image.imageObj.url" alt="">
                    <button class="sidebar-images-delete" @click="onClickImageLightDelete(imageIndex)">
                        <svg-sprite icon="supprimer" width="14" height="14" />
                    </button>
                </swiper-slide>
            </swiper>
        </div>

        <div v-if="libraryImages.length && !lightMode">
            <div class="sidebar-label">Logos récents</div>
            <swiper class="sidebar-images" :options="swiperOptions">
                <swiper-slide v-for="image in libraryImages.slice(0, 8)" :key="image.id">
                    <img :src="image.url" alt="" @click="onClickImage(image)" @error="onImageError(image)">
                    <button class="sidebar-images-delete" @click="onClickImageDelete(image)">
                        <svg-sprite icon="supprimer" width="14" height="14" />
                    </button>
                </swiper-slide>
            </swiper>
        </div>

        <button class="addbutton" @click.prevent="openPopup('PopupLibrary')">
            <svg-sprite icon="ajouter" width="17" height="17" />
            <span>{{ $t('imageupload.add') }}</span>
        </button>
        <button class="addbutton" @click.prevent="openPopup('PopupMotifs')" v-if="!lightMode">
            <svg-sprite icon="ajouter" width="17" height="17" />
            <span>{{ $t('imageupload.addOpenSource') }}</span>
        </button>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { swiper, swiperSlide } from "vue-awesome-swiper"
    import Constants from "../../config/Constants";

    export default {
        name: 'SidebarImageUpload',
        components: {swiper, swiperSlide},
        data: () => ({
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 15,
                freeMode: true,
                grabCursor: true
            }
        }),
        methods: {
            openPopup(slug) {
                this.$store.commit('popup/open', {slug})
            },
            onClickImage(image) {
                this.$store.commit('popup/open', {
                    slug: 'PopupImageEdit',
                    params: {
                        image: {
                            imageObj: image
                        },
                        edit: false
                    }
                })
            },
            onImageError(image) {
                this.$store.commit('library/removeImage', {id: image.id})
            },
            onClickImageDelete(image) {
                if(confirm('Supprimer ce logo ?')) {
                    this.$store.commit('library/removeImage', {id: image.id})
                }
            },
            onClickImageLightDelete(index) {
                this.$store.commit('editor/removeItem', index)
            }
        },
        computed: {
            ...mapGetters({
                libraryImages: 'library/images',
                lightMode: 'product/light'
            }),
            lightLogos() {
                const currentMarquage = this.$store.getters['marquage/selected']
                if(currentMarquage) {
                    return this.$store.getters['editor/itemsByMarquage']({
                        type: Constants.EDITOR.TYPE_IMAGE,
                        marquage: currentMarquage
                    })
                }
                return []
            }
        }
    }
</script>
