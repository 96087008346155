import config from '../../../config.json'
import axios from 'axios'

const state = {
    prices: {
        total: 0,
        groups: []
    },
    quantitiesErrors: null,
    qtyMin: 0
}

const getters = {
    prices: (state) => {
        return state.prices
    },
    quantitiesErrors: (state) => {
        return state.quantitiesErrors
    },
    qtyMin: (state) => {
        return state.qtyMin
    },
    priceForVariant: (state) => (variantId) => {
        let price = 0
        if(state.prices.groups) {
            for(const group of state.prices.groups) {
                if(group.details) {
                    for(const detail of group.details) {
                        if(detail.variant == variantId) {
                            price += detail.total
                        }
                    }
                }
            }
        }
        return price
    },
    priceForTechnique: (state) => (techniqueRef, variantId) => {
        let priceTechnique = null
        let priceVariant = null
        if(state.prices.groups) {
            for(const group of state.prices.groups) {
                if(group.details) {
                    for(const detail of group.details) {
                        if(detail.markingTypeVariantId == variantId) {
                            priceVariant = detail
                        } else if(detail.reference == techniqueRef) {
                            priceTechnique = detail
                        }
                    }
                }
            }
        }
        return priceVariant ? priceVariant : priceTechnique
    }
}

const actions = {
    /**
     * call api to calculate price for current customization
     * @param commit
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    updatePrice({commit}, data) {
        return axios
            .post(process.env.VUE_APP_CONFIGURATEUR_API_URL + 'configurateur/getForProduct', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                commit('setPrices', response.data)
            })
    }
}

const mutations = {
    /**
     * update cart prices : total + details array
     * @param state
     * @param prices
     */
    setPrices(state, prices) {
        state.prices = {
            ...state.prices,
            ...prices
        }
    },
    setQuantityErrors(state, errors) {
        state.quantitiesErrors = errors
    },
    setQtyMin(state, errors) {
        state.qtyMin = errors
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
