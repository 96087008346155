<template>
    <div class="colorpicker-color" :class="{'has-border': color == '#ffffff' || color == '#FFFFFF'}" :style="style" @click.prevent="$emit('selected')">
        <div class="colorpicker-color-label" v-if="label">{{ label }}</div>
    </div>
</template>

<script>
    export default {
        name: 'ColorPickerItem',
        props: {
            color: String,
            label: String
        },
        computed: {
            style() {
                return {
                    'background-color': this.color
                }
            }
        }
    }
</script>
