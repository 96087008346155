<template>
    <div>
        <button class="addbutton" @click.prevent="addText" :disabled="reachedMax">
            <svg-sprite icon="ajouter" width="17" height="17" />
            <span>{{ $t('texts.add') }}</span>
        </button>
        <div class="texts">
            <SidebarTextItem
                v-for="(text, index) in texts"
                :text="text"
                :index="index"
                :key="index"
            ></SidebarTextItem>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import SidebarTextItem from './SidebarTextItem'
    import Constants from '../../config/Constants'

    export default {
        name: 'SidebarTexts',
        components: {SidebarTextItem},
        computed: {
            ...mapGetters({
                selectedMarquage: 'marquage/selected'
            }),
            texts() {
                return this.$store.getters['editor/itemsByMarquage']({
                    marquage: this.selectedMarquage,
                    type: Constants.EDITOR.TYPE_TEXT
                })
            },
            reachedMax() {
                return Object.keys(this.texts).length > Constants.MAX_TEXTS - 1
            }
        },
        methods: {
            addText() {
                if(this.reachedMax) return

                // copy last text values by default
                const textKeys = Object.keys(this.texts)
                let lastText = {}
                if(textKeys.length) {
                    lastText = this.texts[textKeys[textKeys.length - 1]]
                }

                // get color used on this marking (with images and cliparts)
                const color = this.$store.getters['editor/latestItemColor'](this.selectedMarquage)
                if(color) {
                    lastText = {
                        ...lastText,
                        color: color
                    }
                }

                this.$store.commit('editor/addItem', {
                    marquage: this.selectedMarquage,
                    type: Constants.EDITOR.TYPE_TEXT,
                    defaultValues: lastText
                })
            }
        }
    }
</script>
