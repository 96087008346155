<script>
export default {
    data: () => ({
        closed: false
    }),
}
</script>

<template>
<div class="topmessage" v-if="!closed">
    <div class="topmessage-body">
        Le configurateur vous offre un aperçu de votre création,<br>
        Une fois votre commande validée, <b>vous recevrez par mail sous 24/48h, un Bon À Tirer (BAT) pour validation.</b>
    </div>
    <button class="topmessage-close" @click="closed = true"><svg-sprite icon="fermer-noir" width="10" height="10" /></button>
</div>
</template>