<template>
    <div class="sidebar" :class="{'is-active': $store.state.sidebarLeftActive, 'is-toggled': $store.state.sidebarDesktopToggled}">
        <div class="sidebar-top">
            <div class="sidebar-top-title">{{ $t('sidebar.title') }}</div>
            <button class="sidebar-top-close" @click.prevent="$store.commit('toggleSidebarLeft')">{{ $t('apply') }}</button>
        </div>
        <div class="sidebar-body">
            <SidebarLightInfo v-if="$store.getters['product/light']" />
            <SidebarVariantAlt
                v-if="!$store.getters['product/light']"
                v-for="(variant, slug) in variants"
                :variant="variant"
                :slug="slug"
                :key="slug"
            />
            <SidebarQuantityLight v-if="$store.getters['product/light']" />
            <SidebarMarquages />

            <section class="sidebar-section">
                <div class="sidebar-section-header inner box has-large-padding">
                    <h1 class="sidebar-title"><span class="steptag">ÉTAPE 3</span> Personnalisez votre produit</h1>
                    <SidebarImageUpload />
                    <SidebarTexts />
                </div>
            </section>
            <SidebarComments />
        </div>
        <button class="sidebar-toggledesktop" title="Réduire / agrandir la colonne" @click.prevent="$store.commit('toggleSidebarDesktop')"></button>
    </div>
</template>

<script>
    import SidebarMarquages from './SidebarMarquages'
    import SidebarTexts from './SidebarTexts'
    import SidebarImageUpload from './SidebarImageUpload'
    import SidebarLightInfo from './SidebarLightInfo'
    import {mapState} from 'vuex'
    import Cart from '../cart/Cart'
    import SidebarVariantAlt from "./SidebarVariantAlt.vue"
    import SidebarComments from "./SidebarComments.vue";
    import SidebarQuantityLight from "./SidebarQuantityLight.vue";

    export default {
        name: 'Sidebar',
        components: {
            SidebarQuantityLight,
            SidebarComments,
            SidebarVariantAlt,
            SidebarImageUpload, Cart, SidebarTexts, SidebarMarquages, SidebarLightInfo},
        computed: mapState({
            variants: state => state.variants.variants,
        }),
    }
</script>
