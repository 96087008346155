<template>
    <div id="app" :class="{ 'is-loading': loading }">
<!--        <div class="version">v{{ version }}</div>-->
<!--        <RefSelect/>-->
        <transition name="fade">
            <div class="loader-screen" v-if="loading">
                <loader />
                <div class="loader-screen-phrase"></div>
            </div>
        </transition>
        <button class="sidebar-toggle" @click.prevent="$store.commit('toggleSidebarLeft')">
            <span class="sidebar-toggle-icon"></span>
            <span class="sidebar-toggle-text">{{ $t('sidebar.title') }}</span>
        </button>
        <button class="mobileclose" @click="onClickBack"></button>
        <Sidebar />
        <div class="main box" style="padding: 0;">
            <TopMessage />
            <Viewer></Viewer>
        </div>
        <Pricebar />
        <Notification />
        <Popup />
        <Screenshots />
    </div>
</template>

<script>
    import Sidebar from './components/sidebar/Sidebar'
    import Viewer from './components/viewer/Viewer'
    import config from '../config.json'
    import RefSelect from './components/dev/RefSelect'
    import Notification from './components/ui/Notification'
    import Popup from './components/popup/Popup'
    import Logo from './components/ui/Logo'
    import Header from './components/ui/Header'
    import Pricebar from "./components/pricebar/Pricebar.vue";
    import Screenshots from "@/components/screenshot/Screenshots.vue";
    import TopMessage from "./components/ui/TopMessage.vue";

    export default {
        name: 'app',
        data: () => ({
            version: config.version,
            loading: true
        }),
        components: {
            TopMessage,
            Screenshots,
            Pricebar,
            Header,
            Logo,
            Popup,
            Notification,
            RefSelect,
            Viewer,
            Sidebar,
        },
        created() {
            const urlParams = new URLSearchParams(window.location.search)
            if(urlParams.get('preview') === '1') {
                document.body.classList.add('is-preview')
                if(urlParams.get('previewText') === '1') {
                    document.body.classList.add('is-preview-text')
                }
            }
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search)
            const params = {}
            if(urlParams.get('share')) {
                params.shareId = urlParams.get('share')
            } else if(urlParams.get('product')) {
                params.ref = urlParams.get('product')
            } else {
                params.ref = '994/Z1921'
            }
            params.idProduct = urlParams.get('id_product')

            this.$store.dispatch('getProduct', params).then(() => {
                this.loading = false
                if (this.$store.getters['product/light']) {
                    document.body.classList.add('is-light')
                }
                if(urlParams.get('color')) {
                    const variants = this.$store.getters['variants/all']
                    if(variants) {
                        for(const variantSlug of Object.keys(variants)) {
                            const variant = variants[variantSlug]
                            for(const option of Object.values(variant.options)) {
                                if(option.idPrestashopProductVariant == urlParams.get('color')) {
                                    this.$store.commit('variants/selectOption', {
                                        variant: variantSlug,
                                        option: option.id
                                    })
                                }
                            }
                        }
                    }
                }
                this.$store.dispatch('history/snapshotAll')
            })
        },
        methods: {
            onClickBack() {
                window.parent.postMessage({
                    type: 'configuratorClose'
                }, '*')
            },
        }
    }
</script>

<style lang="scss">
    @import "sass/style";
</style>
