
const state = {
    variants: {
    },
    subvariantSelected: null
}

const getters = {
    /**
     * get selected option for a variant slug
     * @param state
     * @returns {Function}
     */
    selectedOption: (state) => (slug) => {
        if(!slug) {
            slug = Object.keys(state.variants)[0]
        }
        let variant = state.variants[slug]
        if(variant && variant.selected) {
            return variant.options[variant.selected]
        }
        return false
    },
    /**
     * selected option for each variants
     * @param state
     */
    selectedOptions: (state) => {
        let options = {}
        for(const slug in state.variants) {
            options[slug] = state.variants[slug].selected
        }
        return options
    },
    selectedColor: (state) => {
        for(const slug in state.variants) {
            const variant = state.variants[slug]
            if(variant && variant.selected && variant.options[variant.selected] && variant.options[variant.selected].colors) {
                return variant.options[variant.selected].colors
            }
        }
        return false
    },
    all: (state) => {
        return state.variants
    },
    subvariantSelected: (state) => {
        return state.subvariantSelected
    },
    totalQuantity: (state) => {
        let total = 0
        for(const variant of Object.values(state.variants)) {
            if(variant.options) {
                for(const option of Object.values(variant.options)) {
                    total += option.config.quantity
                }
            }
        }
        return total
    },
    grilleTarifProduit: (state, getters) => {
        const selectedOption = getters.selectedOption()
        let grille = null
        if(selectedOption) {
            if(selectedOption.hasSubVariants) {
                const subvariantSelected = getters.subvariantSelected
                if(subvariantSelected) {
                    const subvariant = selectedOption.subVariants.find(item => item.id == subvariantSelected)
                    if(subvariant) {
                        grille = subvariant.priceGrid
                    }
                }
            } else {
                grille = selectedOption.priceGrid
            }
        }
        if(grille) {
            if (grille.length === 0) {
                grille = null
            } else {
                if(grille[0].floor == 1) {
                    // shift
                    // grille.shift()
                }
            }
        }
        return grille
    }
}

const actions = {
}

const mutations = {
    /**
     * init variants from product info
     * @param state
     * @param items
     * @param selected
     */
    init(state, { items }) {
        state.variants = {
            ...state.variants,
            ...items
        }
    },

    /**
     * select an option for a variant
     * @param state
     * @param variant
     * @param option
     */
    selectOption(state, { variant, option }) {
        if(!state.variants[variant]) return
        state.variants[variant].selected = option
    },

    /**
     * select a subvariant
     * @param state
     * @param subvariantId
     */
    selectSubvariant(state, { subvariantId }) {
        state.subvariantSelected = subvariantId
    },

    setOptionQuantity(state, { variantId, optionId, quantity, subVariantId }) {
        if(!variantId) {
            variantId = Object.keys(state.variants)[0]
        }
        if(!state.variants[variantId] || !state.variants[variantId].options[optionId]) return
        const option = state.variants[variantId].options[optionId]
        let newQuantity = parseInt(quantity)

        if(subVariantId) {
            newQuantity = 0
            for(const variant of option.subVariants) {
                if(variant.id === subVariantId) {
                    variant.config.quantity = parseInt(quantity)
                }
                newQuantity += variant.config.quantity
            }
        }

        option.config.quantity = newQuantity
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
