<template>
    <section class="sidebar-section" style="overflow: hidden;">
        <div class="sidebar-section-header inner box has-large-padding">
            <h1 class="sidebar-title"><span class="steptag">ÉTAPE 1</span> {{ variantLabel }}</h1>
            <div v-if="hasMultipleVariants">
                <div class="variant-spotsgrid" :class="{ 'is-condensed': variant.options && Object.values(variant.options).length > 20 }">
                    <div class="variant-spotsgrid-item"
                         v-for="(option, optionSlug) in variant.options"
                         :key="optionSlug"
                         @click="selectOption(optionSlug); $store.commit('setSidebarToggle', 'variantalt-' + slug)"
                    >
                        <VariantSpot :option="option" :quantity="option.config.quantity" :selected="variant.selected === optionSlug" :disabled="!variantEnabled(optionSlug).enabled" />
                    </div>
                </div>
            </div>
            <div v-else>
                <template class="variant-item"
                          v-for="(option, optionSlug) in variant.options"
                >
                    <SidebarVariantQuantities :variant-slug="slug" :option="option" :option-slug="optionSlug" />
                </template>
            </div>
        </div>
        <SidebarToggle :slug="'variantalt-' + slug">
            <SidebarVariantQuantities v-if="selectedOption" :variant-slug="slug" :option="selectedOption" :option-slug="variant.selected" />
        </SidebarToggle>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import VariantSpot from '../variant/VariantSpot'
    import SidebarToggle from './SidebarToggle'
    import SidebarVariantQuantities from "./SidebarVariantQuantities.vue";

    export default {
        name: 'SidebarVariantAlt',
        components: {SidebarVariantQuantities, SidebarToggle, VariantSpot},
        data: () => ({}),
        props: {
            variant: Object,
            slug: String
        },
        mounted() {
            setTimeout(() => {
                if(this.hasMultipleVariants) {
                    const selectedOptions = this.$store.getters['variants/selectedOptions']
                    let selectedOption = Object.keys(this.variant.options)[0]
                    if(selectedOptions) {
                        const optionsValues = Object.values(selectedOptions)
                        if(optionsValues.length) {
                            selectedOption = optionsValues[0]
                        }
                    }
                    this.selectOption(selectedOption)
                    this.$store.commit('setSidebarToggle', 'variantalt-' + this.slug)
                }
            }, 1000)
        },
        computed: {
            ...mapGetters({
                variantEnabled: 'marquage/variantEnabled'
            }),
            selectedOption() {
                return this.$store.getters['variants/selectedOption'](this.slug)
            },
            hasMultipleVariants() {
                return this.variant.options && Object.values(this.variant.options).length > 1
            },
            /**
             * check if options has sub variants
             */
            hasSubVariants() {
                const option = Object.values(this.variant.options)[0]
                return option && option.hasSubVariants
            },
            variantLabel() {
                let label = this.variant.label ? this.variant.label : 'Couleur & quantité'
                return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()
            }
        },
        methods: {
            /**
             * select a variant option, if already selected display other option choices
             * @param slug
             */
            selectOption(slug) {
                const selectedOption = this.$store.getters['variants/selectedOption'](this.slug)
                if(selectedOption && selectedOption.id === slug) {
                    this.$store.commit('setSidebarToggle', 'variant-' + this.slug)
                } else {
                    this.$store.commit('variants/selectOption', {
                        variant: this.slug,
                        option: slug
                    })
                }
            },
            setQuantity(event, optionSlug, subVariantSlug = null) {
                let qty = 0
                if (event.target.value.length > 0) {
                    qty = event.target.value
                }
                this.$store.commit('variants/setOptionQuantity', {
                    variantId: this.slug,
                    optionId: optionSlug,
                    subVariantId: subVariantSlug,
                    quantity: qty
                })
            }
        }
    }
</script>
